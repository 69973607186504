<template>
    <OModal ref="nextStepDlg">
        <div class="modal-dialog modal-lg">
            <div class="modal-content rounded-0">
                <div class="modal-header bg-primary text-white rounded-0">
                    <div class="hstack gap-2">
                        <h5 class="modal-title" id="staticBackdropLabel">
                            {{$t("Step") }}: {{ processStepDataObject.current.Name }}
                        </h5>
                        <div v-if="isExecutingProc" class="spinner-border spinner-border-sm" style="0.35rem;" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                    <button type="button" class="btn-close-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body bg-light">                    

                    <div v-if="processStepDataObject.current.ChannelSource === null" class="row">     
                        <!-- <ODataLookup v-if="dsItemsOrgUnitsLkp.data.length > 1" -->
                            <!-- :dataObject="dsItemsOrgUnitsLkp" --> 
                            <!-- :bind="setActionBy" -->
                            <!-- disableRecent> -->
                            <!-- <OColumn field="OrgUnit" width="300"></OColumn> -->
                            <!-- <template #target="{ target }"> -->
                                <!-- <span style="cursor:pointer;" class="dropdown-toggle " :ref="target">{{$t("Action By Org Unit")}} <span style="font-weight: bold;">{{ actionBy }}</span></span> -->
                            <!-- </template> -->
                        <!-- </ODataLookup> -->
                        <div v-if="dsItemsOrgUnitsLkp.data.length > 1" class="col-12">
                            <span>{{$t("Set Action By Org Unit")}}</span>
                            <div v-for="row in dsItemsOrgUnitsLkp.data" class="form-check">
                                <label class="form-check-label" >
                                    <input class="form-check-input"  :value="{OrgUnit_ID: row.OrgUnit_ID, OrgUnit: row.OrgUnit}" v-model="selectedActionBy" type="radio">
                                    {{ row.OrgUnit }}
                                </label>
                            </div>
                        </div>

                        <!--<ODataLookup v-if="dsProcessStepRoles.data.length > 1"
                            :dataObject="dsProcessStepRoles" 
                            :bind="setRole"
                            disableRecent>
                            <OColumn field="Role" width="300"></OColumn>
                            <template #target="{ target }">
                                <span style="cursor:pointer;" class="dropdown-toggle " :ref="target"><span style="font-weight: bold;" class="text-muted">{{$t("Role")}}:</span> <span>{{ role }}</span></span>
                            </template>
                        </ODataLookup>-->

                        <div class="mt-3" v-if="workflowDataObject.current.HasRespRoles_ID && !processStepDataObject.current.RestrictStepCompletionToRoleTypeMembers"> {{$t("Select a responsible from an involved party")}}</div>
                        <div class="col-6" v-if="workflowDataObject.current.HasRespRoles_ID && !processStepDataObject.current.RestrictStepCompletionToRoleTypeMembers">
                            <ODataLookup 
                                :bind="onItemsOrgUnitsResp"
                                :dataObject="dsItemsOrgUnitsRespLkp"
                            >
                                <OColumn field="OrgUnit" width="350"></OColumn>
                                <OColumn field="Role" width="300"></OColumn>
                                <OColumn field="Name" width="200"></OColumn>
                                <OColumn field="Represents" width="250"></OColumn>
                                <template #target="{ target }">
                                    <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc" :ref="target">{{$t("Responsible Involved Parties ")}}</button>
                                </template>
                            </ODataLookup>
                        </div>
                        
                        <div class="mt-3"> {{$t("Select a person")}}</div>
                        <div class="col-6" v-if="processStepDataObject.current.Role_ID || dsProcessStepRoles.data.length > 1">
                            <OPersonsLookup 
                                :dataObject="dsRoleMembersNS" 
                                :bind="onRoleMemberSelected"
                                :whereClause="roleMembersWhereClause"
                                reloadOnWhereClauseChange
                            >
                                <template #target="{ target }">
                                    <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc || restrictStepToRole" :ref="target">{{$t("Role Members")}}</button>
                                </template>
                            </OPersonsLookup>
                        </div>
                        <div class="col-6">
                            <OPersonsLookup 
                                v-if="!processStepDataObject.current.RestrictStepCompletionToRoleTypeMembers"
                                :bind="onPersonSelected"
                                :dataObject="dsPersonLkp"
                                forceReloadOnOpen
                            >
                                <template #target="{ target }">
                                    <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" :ref="target">{{$t("Person With Access")}}</button>
                                </template>
                                <template #toolbarActions v-if="!hideRestrictToPersonWithAccess">
                                    <OToolbarAction :text="$t('Restrict To Persons With Access')" v-model="restrictPersonsLkpToPersonsWithAccess" />
                                </template>
                            </OPersonsLookup>
                            <OPersonsLookup 
                                v-else
                                :bind="onPersonSelected"
                                :dataObject="dsPersonsRTRTLkp"
                                :whereClause="personsRTRTLkpWhereClause"
                                forceReloadOnOpen
                            >
                                <template #target="{ target }" >
                                    <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" :ref="target">{{$t("Person With Access")}}</button>
                                </template>
                            </OPersonsLookup>
                        </div>
                        
                        <div class="col-6">
                            <button @click="meSelected" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" class="w-100 btn btn-sm btn-outline-primary mb-1">{{ $t("Me") }}: {{userSession.name}} </button>
                        </div>
                        <div v-if="!workflowSentViaChannel && isSubmittedItem" class="col-6">
                            <button @click="originatorSelected" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" class="w-100 btn btn-sm btn-outline-primary mb-1">{{$t("Originator")}}: {{ workflowDataObject.current.CreatedBy }}</button>
                        </div>

                        <div class="mt-3" v-if="processStepDataObject.current.ForceFixedToPerson == false && changeResponsible == false && roleId !== null">  
                            <div>{{$t("Do not select a person, but ...")}} </div>
                            <button class=" btn btn-primary mb-1 w-100" :disabled="isExecutingProc" @click="onAllMembersSelected">{{ $t("Send To All Members In Role") }}: {{ role }} </button>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="mt-3"> {{$t("Via channel to")}} <span class="fw-bold">{{ processStepDataObject.current.ChannelSource }}: {{ processStepDataObject.current.ChannelName }}</span></div>
                        <div class="col-6">
                            <button class="btn btn-sm btn-outline-primary mb-1 w-100" :disabled="isExecutingProc" @click="moveStep({ Person_ID: userSession.personId })">{{ $t("Send") }}</button>
                        </div>
                    </div>
                
                    <div class="row" v-if="changeResponsible == true && props.stepRow.Person_ID">                        
                        <div class="col-6 mt-2">
                            <button class=" btn btn-sm btn-outline-primary mb-1 w-100" @click="clearResponsible()" >{{$t("Clear Responsible") }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
import { getOrCreateProcedure, userSession } from 'o365-modules';
import { ref, defineExpose, computed, watch } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import { OModal } from 'o365-ui-components';
import { OPersonsLookup } from 'o365-system-lookups';
import { Url } from 'o365-utils';

const emit = defineEmits(["submitted"]);

const props = defineProps({
    workflowDataObject: {
        type: Object,
        required: true
    },
    processStepDataObject: {
        type: Object,
        required: true
    },
    stepRow: {
        type: Object,
        required: false
    },
    itemStepId: {
        type: Number,
        required: false,
    },
    hideRestrictToPersonWithAccess : {
        type: Boolean,
        default: false
    }
});

defineExpose({ show });

// Data object definitions
const dsItemsOrgUnitsLkp = getOrCreateDataObject({
    id: 'dsItemsOrgUnitsLkp' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ItemsOrgUnitsAndSelf",
    maxRecords: -1,
    whereClause: `Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: true,
    selectFirstRowOnLoad: true,
    fields: [{ name: "OrgUnit", sortOrder: 1, sortDirection: "desc"},  { name: "OrgUnit_ID" }],
});

const dsRoleMembersNS = getOrCreateDataObject({
    id: 'dsRoleMembersNS' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ItemsStepsPersonsRoleMembers",
    maxRecords: -1,
    loadRecents: false,
    distinctRows: true,
    fields: [{ name: "ID" }, { name: "Name", sortOrder: 1, sortDirection: "asc"  }, { name: "MobileNo" }, { name: "Email" }, { name: "Represents" }],
});

const dsPersonLkp = getOrCreateDataObject({
    id: 'dsPersonLkp' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_PersonsLookup",
    definitionProc:"astp_Scope_PersonsLookupDefinition",
    maxRecords: 25,
    //whereClause: `Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: false,
    fields: [{ name: "ID" }, { name: "Name", sortOrder: 1, sortDirection: "asc" }, { name: "MobileNo" }, { name: "Email" }, { name: "Represents" }],
});

const dsPersonsRTRTLkp = getOrCreateDataObject({
    id: "dsPersonsRTRTLkp" + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_PersonsLookupRTRTForProcessStep",
    maxRecords: 25,
    whereClause: `ProcessStep_ID = ${props.processStepDataObject.current.ID} AND Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: true,
    fields: [{ name: "ID" }, { name: "Name" }, { name: "MobileNo" }, { name: "Email" }, { name: "Represents" }],
});

const dsItemsOrgUnitsRespLkp = getOrCreateDataObject({
    id: 'dsItemsOrgUnitsResp' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ItemsOrgUnitsResponsibles",
    maxRecords: 25,
    whereClause: `Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: true,
    fields: [{ name: "OrgUnit" }, { name: "Name" }, { name: "ID" }, { name: "Role" }, { name: "Represents" }],
});

const dsProcessStepRoles = getOrCreateDataObject({
    id: 'dsProcessStepRoles' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ProcessesStepsRolesWithDefaultStepRole",
    maxRecords: -1,
    loadRecents: false,
    selectFirstRowOnLoad: true,
    distinctRows: true,
    fields: [{ name: "Role_ID" }, { name: "Role" }],
});

// Procedures
const procMoveStep = getOrCreateProcedure({ id: "procMoveStep", procedureName: "astp_Scope_ItemsStepsMove" });
const procInitiateWorkflow = getOrCreateProcedure({ id: "procInitiate", procedureName: "astp_Scope_ItemInitiate" });

// Refs
const nextStepDlg = ref(null);
const isExecutingProc = ref(false);
const role = ref(null);
const roleId = ref(null);
const actionBy = ref(null);
const actionById = ref(null);
const changeResponsible = ref(false);
const restrictPersonsLkpToPersonsWithAccess = ref(true);


// Computed values
const selectedActionBy = computed({
    get() { return {
        OrgUnit: actionBy.value,
        OrgUnit_ID: actionById.value
    }},
    set(pVal) {
        actionBy.value = pVal.OrgUnit;
        actionById.value = pVal.OrgUnit_ID;
    }
});
const currentOrgUnitId = computed(() => dsItemsOrgUnitsLkp.current.OrgUnit_ID);
const isSubmittedItem = computed(() => !props.workflowDataObject.current.Hidden);
const restrictStepToRole = computed(() => props.workflowDataObject.current.RestrictToRole);
const restrictStepToRoleMembers = computed(() => props.processStepDataObject.current.RestrictToRoleMembers);
const workflowSentViaChannel = computed(() => !!props.workflowDataObject.current.Channel || !!props.workflowDataObject.current.ChannelRef);
const roleMembersWhereClause = computed(() => `Role_ID = ${roleId.value} AND OrgUnit_ID = ${actionById.value} AND ReadOnly = 0`);


// Computed lookup where clauses
const personsRTRTLkpWhereClause = computed(() => `ProcessStep_ID = ${props.processStepDataObject.current.ID} AND Item_ID = ${props.workflowDataObject.current.ID}`);

async function show(pChangeResponsible) {
    if (pChangeResponsible) {
        changeResponsible.value = true;
    } else {
        changeResponsible.value = false;
    }
    nextStepDlg.value.show();
    
    dsProcessStepRoles.recordSource.whereClause = "Step_ID = " + props.processStepDataObject.current.ID;
    dsProcessStepRoles.load();

    dsItemsOrgUnitsLkp.recordSource.whereClause = `Item_ID = ${props.workflowDataObject.current.ID}`;
    dsItemsOrgUnitsLkp.load();

    dsItemsOrgUnitsRespLkp.recordSource.whereClause = `Item_ID = ${props.workflowDataObject.current.ID}`;
    dsItemsOrgUnitsRespLkp.load();

    roleId.value = props.processStepDataObject.current.Role_ID;    

    setDefaultRole();
    setDefaultActionBy();
}

dsPersonLkp.recordSource.sqlStatementParameters = {
    Item_ID: props.workflowDataObject.current.ID,
    AllPersons: false
}

watch(restrictPersonsLkpToPersonsWithAccess, restrict => {
    dsPersonLkp.recordSource.sqlStatementParameters = {
        Item_ID: props.workflowDataObject.current.ID,
        AllPersons: (restrict?false:true)
    }

    if (dsPersonLkp.state.isLoaded) {
        dsPersonLkp.load();
    }
});

function setDefaultRole() {
    setRole(props.processStepDataObject.current);
}

function setDefaultActionBy() {
    actionById.value = props.workflowDataObject.current.ActionBy_ID;
    actionBy.value = props.workflowDataObject.current.ActionBy;
}

function setRole(pRow) {
    roleId.value = pRow.Role_ID;
    role.value = pRow.Role;
}

function onAllMembersSelected() {
    moveStep({ OrgUnit_ID: currentOrgUnitId.value });
}

function onRoleMemberSelected(row) {
    moveStep({ Person_ID: row.ID });
}

function onPersonSelected(row) {
    moveStep({ Person_ID: row.ID });
}

function onItemsOrgUnitsResp(row){
    moveStep({ Person_ID: row.ID });
}

function meSelected() {
    if (isSubmittedItem.value) {
        moveStep({ SetMeAsResp: true });
    } else {
        moveStep({ Person_ID: userSession.personId });
    }
}

function originatorSelected() {
    moveStep({ Person_ID: props.workflowDataObject.current.CreatedBy_ID });
}

async function moveStep(params) {
    if(changeResponsible.value == false){
        props.workflowDataObject.current.ActionBy_ID = actionById.value;
        props.workflowDataObject.current.ActionBy = actionBy.value;
        await props.workflowDataObject.save();
        const isExistingItem = !props.workflowDataObject.current.Hidden;
        const procToBeExecuted = isExistingItem ? procMoveStep : procInitiateWorkflow;
        const defaultParams = { 
            ProcessStep_ID: props.processStepDataObject.current.ID,
            OrgUnit_ID: props.workflowDataObject.current.OrgUnit_ID,        
            ...isExistingItem ? {
                // Move existing Item params
                Item_ID: props.workflowDataObject.current.ID,
                ItemStep_ID: props.itemStepId ?? props.workflowDataObject.current.Step_ID ?? props.workflowDataObject.current.CurrentStep_ID,
                Role_ID: roleId.value,
            } : { 
                // Initiate new Item params
                ID: props.workflowDataObject.current.ID,
                ProcessStep_ID: props.processStepDataObject.current.ID,
                OrgUnit_ID: props.workflowDataObject.current.OrgUnit_ID,
                AgendaItemUrl_ID: Url.getParam('AgendaItemUrl_ID'),
                AgendaItem_ID: Url.getParam('AgendaItem_ID')
            }
        };

        isExecutingProc.value = true;
        return procToBeExecuted.execute({ ...defaultParams, ...params }).then(onSubmitted).finally(() => (isExecutingProc.value = false));
    } else {
        if (params.SetMeAsResp) {
            props.stepRow.Person_ID = userSession.personId;
        } else {
            props.stepRow.Person_ID = params.Person_ID;
        }
        await props.stepRow.save();
        nextStepDlg.value.hide();
    }
}

async function clearResponsible(){
    props.stepRow.Person_ID = null;
    await props.stepRow.save();
    nextStepDlg.value.hide();
}

function onSubmitted(procResponse) {
    nextStepDlg.value.hide();
    emit("submitted", procResponse);
}

</script>